"use strict";

console.log('Bien le bonjour ');
var headNav2 = document.querySelector('.head');
var btnClose = document.querySelector(".nav__img2") ;

var btnMenu = document.querySelector('#nav__img');
btnMenu.addEventListener("click", (e)=>{
    if(document.body.hasAttribute("data-navMenu")){
        document.body.removeAttribute("data-navMenu");

        console.log('Bien le bieu');
    }else{
        document.body.setAttribute("data-navMenu", true);
        
        console.log('Bien le bonsoir ');
    }
})
